import React from 'react'
import { PageProps } from 'gatsby'

import { BadgeCheckIcon } from '@heroicons/react/outline'
import Footer from '../components/Footer'
import Header from '../components/Header'
import ContentDetails from '../components/ContentDetails'

const LFCR = '\u000A \u000D'

const mainFeatureItems = [
  {
    id: 1,
    name: `By accessing the website, you accept and agree the following terms:`,
    description:
      `Sekyee Computer Limited accept no responsibility for any loss or damage of whatever nature arising in any way out of the use of, or inability to use, this website or from any error or omission in information contained in this website. Sekyee Computer Limited reserve the absolute right to alter, suspend or discontinue any aspect of this website or the services provided through it, including your access to it.
      ${LFCR}Copyright and other intellectual property rights in the content of this website, including any Sekyee Computer Limited marks, logos and brands contained on the website, belong to Sekyee Computer Limited or its licensor(s) – who have expressly licensed content to Sekyee Computer Limited
      ${LFCR}Any links to other websites have been included for convenience only and Sekyee Computer Limited accept no responsibility or liability for the contents of, or any loss or damage caused or alleged to be caused by or in connection with use or reliance on the content of, any linked website. The inclusion of any link does not imply endorsement by Sekyee Computer Limited of any linked website or its provider.
      ${LFCR}Whilst every care is taken to ensure that the information displayed on this website is correct, no warranty or representation is given to its quality, accuracy, fitness for purpose, usefulness, or completeness, in particular the materials on this website do not give specific
      ${LFCR}legal advice and should not be relied upon as doing so users should be aware that laws and regulations might be different outside England & Wales.
      ${LFCR}Sekyee Computer Limited will only use if any personal data collected during our use of this website in accordance with the current UK data protection legislation and our privacy policy.
      ${LFCR}You agree to indemnify and hold Sekyee Computer Limited, and any of its officers, employees, and agents harmless from and against all and any expenses, losses, liabilities, damages, costs or expenses of any character incurred or suffered and any claims or legal proceedings which are brought or threatened, in each case arising from misuse of or conduct on, this website and/or breach of these terms.`,
    icon: BadgeCheckIcon,
  },
  {
    id: 2,
    name: `Privacy Policy`,
    description:
      `www.sekyee.co.uk is a general audience web site, intended for users of all ages.`,
    icon: BadgeCheckIcon,
  }
]

const TermsConditions = (props: PageProps) => {
  return (
    <div className="bg-white">

      <Header path={props.path} />

      <main>
        <ContentDetails 
          title="TERMS AND CONDITIONS" 
          tagline={`Sekyee Computer Limited is a Limited Company registered in England and Wales under number: 03494037 ${LFCR}Registered address: Sekyee Computer Limited 173 Junction Road London ${LFCR}N19 5PZ`}
          mainFeatures={mainFeatureItems} 
          footer="Please read our Privacy Policy" />
      </main>
    
      <Footer />
    </div>
  )
}

export default TermsConditions